import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH} from 'constants/ThemeConstant';
import {APP_NAME} from 'configs/AppConfig';
import {Grid} from 'antd';

const { useBreakpoint } = Grid;

export const Logo = ({ mobileLogo }) => {
    const { navCollapsed, navType } = useSelector(state => state.theme);
    const [logoFull, setLogoFull] = useState();
    const [logoFavicon, setLogoFavicon] = useState();
    const screens = useBreakpoint();
    const isMobile = !screens.sm;
    const isNavTop = navType === NAV_TYPE_TOP;

    useEffect(() => {
    }, []);

    const getLogoWidthGutter = () => {
        if (isMobile && !mobileLogo) return 0;
        if (isNavTop) return 'auto';
        return navCollapsed ? `${SIDE_NAV_COLLAPSED_WIDTH}px` : `${SIDE_NAV_WIDTH}px`;
    };

    const logoDisplay = isMobile && !mobileLogo ? 'd-none' : 'logo';

    return (
        <div className={logoDisplay} style={{ width: getLogoWidthGutter() }}>
            {logoFull || logoFavicon ? (
                <img src={navCollapsed ? logoFavicon : logoFull} alt={`${APP_NAME} logo`} style={{ maxHeight: '40px' }} />
            ) : (
                <span>{APP_NAME}</span>
            )}
        </div>
    );
};

export default Logo;
