import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";
import AppLayout from "layouts/app-layout";
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import AuthViews from "./auth-views";

export const Views = () => {
    const {locale, direction} = useSelector(state => state.theme);
    const {token} = useSelector(state => state.auth);
    useBodyClass(`dir-${direction}`);
    console.log({token})
    return (
        <IntlProvider
            locale={AppLocale[locale].locale}
            messages={AppLocale[locale].messages}>
            <ConfigProvider locale={AppLocale[locale].antd} direction={direction}>
                <Routes>
                    <Route path="/" element={<Navigate to={APP_PREFIX_PATH}/>}/>
                    <Route path={`${AUTH_PREFIX_PATH}/*`} element={<AuthViews/>}/>
                    <Route path={`${APP_PREFIX_PATH}/*`} element={
                        !token ?
                            <Navigate to={`${AUTH_PREFIX_PATH}/login`}/> :
                            <AppLayout direction={direction}/>
                    }/>
                </Routes>
            </ConfigProvider>
        </IntlProvider>
    )
}

export default Views;
