/* eslint-disable */
import React, {lazy, Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import Loading from 'components/shared-components/Loading';

const Login = lazy(() => import(`./authentication/login`));

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="page"/>}>
            <Routes>
                <Route index path="login" element={<Login/>}/>
            </Routes>
        </Suspense>
    )
}

export default AppViews;
