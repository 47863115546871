import React from 'react';
import {Provider} from 'react-redux';
import store from './redux/store';
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeSwitcherProvider} from 'react-css-theme-switcher';
import {PUBLIC_URL, THEME_CONFIG} from './configs/AppConfig';
import './assets/scss/style.scss';
import Views from './views';
import {ConfigProvider} from "antd";

const themes = {
    dark: `${PUBLIC_URL}/css/dark-theme.css`,
    light: `${PUBLIC_URL}/css/light-theme.css`,
};


function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#de4436',
                        },
                    }}
                >
                    <ThemeSwitcherProvider
                        themeMap={themes}
                        defaultTheme={THEME_CONFIG.currentTheme}
                        insertionPoint="styles-insertion-point"
                    >
                        <Router>
                            <Views/>
                        </Router>
                    </ThemeSwitcherProvider>
                </ConfigProvider>
            </Provider>
        </div>
    );
}

export default App;
