const dev = {
    API_BASE_URL:  process.env.REACT_APP_BACKEND_URL || 'https://hrm.dev.dbiz.vn',
    PUBLIC_URL: process.env.PUBLIC_URL,
};

// const prod = {
//     API_ENDPOINT_URL:  process.env.REACT_APP_BACKEND_URL || 'https://hrm.dev.dbiz.vn',
// };
//
// const test = {
//     API_ENDPOINT_URL:  process.env.REACT_APP_BACKEND_URL || 'https://hrm.dev.dbiz.vn',
// };

const getEnv = () => {
    return dev
    // switch (process.env.NODE_ENV) {
    //     case 'development':
    //         return dev;
    //     case 'production':
    //         return prod;
    //     case 'test':
    //         return test;
    //     default:
    //         break;
    // }
};

export const env = getEnv();
