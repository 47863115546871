import {all, fork, put, takeEvery} from 'redux-saga/effects';
import {AUTH_TOKEN, LOGIN_GETWAY, SIGNOUT, USER_INFO,} from '../constants/Auth';
import {handleLoginGetWay, showAuthMessage, signOutSuccess} from "../actions/Auth";


export function* signInWithGetWay() {
    yield takeEvery(LOGIN_GETWAY.REQUEST, function* ({payload :{password,email}}) {
        try {
            if (password === "Admin@123" && email === "admin@dms.com"){
                yield put(handleLoginGetWay.success({
                    token: "access_token",
                    user_info: {password,email},
                    loading: false
                }));

            }else{
                yield put(handleLoginGetWay.failure({
                    message: "Tài khoản hoặc mật khẩu không chính xác",
                    showMessage:true
                }));
            }

        } catch (error) {
            console.error("Error in signInWithGetWay:", error);
            yield put(handleLoginGetWay.failure());
        }
    });
}


export function* signOut() {
    yield takeEvery(SIGNOUT, function* () {
        try {
            localStorage.removeItem(AUTH_TOKEN);
            localStorage.removeItem(USER_INFO);
            yield put(signOutSuccess());

        } catch (err) {
            yield put(showAuthMessage(err));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(signInWithGetWay),
        fork(signOut),
    ]);
}
