import * as actionType from '../constants/Auth';
import {
    AUTH_TOKEN,
    AUTHENTICATED,
    HIDE_AUTH_MESSAGE,
    RESET_AUTH,
    SHOW_AUTH_MESSAGE,
    SHOW_LOADING,
    SIGNIN,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNOUT_SUCCESS,
    SIGNUP_SUCCESS,
    TEAMS_USER_CREDENTIAL,
    USER_INFO
} from '../constants/Auth';

export const initState = {
    loading: false,
    error: false,
    message: '',
    showMessage: false,
    redirect: '/',
    user_info: {},
    token: undefined,
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            localStorage.setItem(AUTH_TOKEN, action.payload);
            return {
                ...state,
                loading: false,
                redirect: '/',
                token: action.token
            }
        case actionType.LOGIN_GETWAY.REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case actionType.LOGIN_GETWAY.SUCCESS:
            return {
                ...state,
                ...action.payload,
                error: false,
                allowRedirect: true,
                message: undefined
            }
        case actionType.LOGIN_GETWAY.FAILURE:
            return {
                ...state,
                token: null,
                user_info: {},
                tokenGetWay: undefined,
                error: true,
                showMessage: true,
                loading: false,
                ...action.payload
            }
        case SIGNIN:
            localStorage.setItem(USER_INFO, JSON.stringify(action.payload));
            return {
                ...state,
                user_info: action.payload
            }
        case TEAMS_USER_CREDENTIAL:
            return {
                ...state,
                teamsUserCredential: action.teamsUserCredential
            }
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false
            }
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: '',
                showMessage: false,
            }
        case SIGNOUT_SUCCESS: {
            localStorage.clear();
            return {
                ...state,
                token: null,
                redirect: '/',
                loading: false
            }
        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                token: action.token
            }
        }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token
            }
        }
        case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token
            }
        }
        case RESET_AUTH.REQUEST: {
            return {...initState, token: undefined};
        }
        default:
            return state;
    }
}

export default auth
