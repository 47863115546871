import {combineReducers} from 'redux';
import {reducer as form} from 'redux-form';
import Auth from './Auth';
import Theme from './Theme';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    form,

});

export default reducers;
