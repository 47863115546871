import {DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig'

export const APP_NAME = 'DMS';
export const KEY_APP_NAME = 'dms_poc';
export const API_BASE_URL = env.API_BASE_URL;
export const PUBLIC_URL = env.PUBLIC_URL;

export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const PATTERN_PHONE = /(^(09|03|07|08|05)+([0-9]{8}$))|(^(84)+([0-9]{9}$))/;
export const PATTERN_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en-us',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#fc000c',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	isKanban: true
};
