import React, {lazy, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Loading from "../../components/shared-components/Loading";

// const DashboardsApp = lazy(() => import(`./dashboards_app/default`));
const SalarySummaries = lazy(() => import(`./salary-summaries`));

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Routes>
                <Route index element={<Navigate to="report" />} />
                {/*<Route path="dashboards-app/*" index element={<DashboardsApp/>}/>*/}
                <Route  path="report/*" element={<SalarySummaries/>}/>
            </Routes>
        </Suspense>
    );
};

export default React.memo(AppViews);
