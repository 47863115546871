import {applyMiddleware, compose, createStore} from 'redux';
import reducers from '../reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import persistState from 'redux-localstorage';
import {initState as authState} from '../reducers/Auth';
import {initTheme as themeState} from '../reducers/Theme';
import {KEY_APP_NAME} from "../../configs/AppConfig";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const enhancers = [
    persistState(undefined, {
        key: KEY_APP_NAME,
        slicer: () => (state) => {
            // filter which substore to persist
            const subset = {
                auth: {
                    ...authState,
                    ...state.auth,
                },
                theme: {
                    ...themeState,
                    ...state.theme,
                },

            };
            return subset;
        },
    }),
];

function configureStore(preloadedState) {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducers,
        preloadedState,
        composeEnhancers(applyMiddleware(...middlewares), ...enhancers),
    );
    sagaMiddleware.run(rootSaga);
    // eslint-disable-next-line no-undef
    if (module.hot) {
        // eslint-disable-next-line no-undef
        module.hot.accept('../reducers/index', () => {
            // eslint-disable-next-line no-undef
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
}

const store = configureStore();

export default store;
